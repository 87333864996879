
import { Header } from "./Header";
export function Home(){
    return(
        <div className="bg-gray-800">

        <Header/>
        <div className=" text-left selection:bg-gray-700 grid gap-4 bg-fixed min-h-screen min-height-screen bg-gray-800 max-w-screen-xl">
        
           <div className = " m-8"> 
           <h1 className=" text-left my-4 text-2xl text-red-200 font-TrapBold"> Hi! I'm Ella :-) </h1>
           <h1 className=" text-left my-4 text-2xl text-purple-200 font-TrapBold"> I'm a senior (already?) at <a target="_blank" className="text-purple-400 hover:underline" href="https://www.northwestern.edu">Northwestern University</a> studying Computer Science with a concentration in Human Computer Interraction.</h1>

           <h1 className=" text-left my-4 text-2xl text-pink-200 font-TrapBold"> I'm passionate about EdTech and supporting students so that they can learn effectively. I'm currently working at <a target="_blank" className="text-pink-400 hover:underline" href="https://www.mathacademy.com/">Math Academy</a> as a researcher and developer, and  I also interned at the <a target="_blank" className="text-pink-400 hover:underline" href="https://learning-engineering-virtual-institute.org/">Learning Engineering Virtual Institute</a> affiliated with the  <a target="_blank" className="text-pink-400 hover:underline" href="https://digitalharbor.org/">Digital Harbor Foundation</a>. </h1>


           <h1 className=" text-left my-4 text-2xl text-blue-200 font-TrapBold"> At school, I participated in <a target="_blank" className="text-blue-400 hover:underline" href="https://dtr.northwestern.edu">DTR</a>, 
           a research lab focusing on HCI. I worked on  a project called <a target="_blank" className="text-blue-400 hover:underline" href="https://dtr.northwestern.edu/projects/recxU2iSY4G2bHdCj"> Process Adjustment by Tackling Hangups </a>,  to help students better understand their own learning!  I also peer mentored for <a target="_blank" className="text-blue-400 hover:underline" href="https://www.mccormick.northwestern.edu/computer-science/academics/courses/descriptions/330-1.html">CS-330</a>, an HCI class at Northwestern.</h1> 
        </div>
        </div>
        </div >

    );
}

